import {MultiStepBaseController} from "./multi_step_base_controller";

const LOG_TYPE = "bio-diaries";
let finalPage = null;
const LOG_URL = "/bio_diaries";

export default class extends MultiStepBaseController {

  connect() {
    this.setPage();
    this.init(LOG_TYPE, finalPage, LOG_URL);
  }

  setPage() {
    let url = new URL(window.location);
    let bioDiariesData = JSON.parse(localStorage.getItem("bio-diaries-data"));
    const page = url.searchParams.get("page");
    const type = url.searchParams.get("type");

    if (!bioDiariesData) {
      finalPage = 5;
      return;
    }

    if (bioDiariesData["finalPage"]) {
      finalPage = bioDiariesData["finalPage"];
    }

    if (page === "2" && (!type || type !== bioDiariesData["bio_diary[bio_type]"])) {
      url.searchParams.set("type", bioDiariesData["bio_diary[bio_type]"]);
      window.location.href = url.href;
    }

    if (page === "3") {
      // bioDiariesData["page"] = 1;

      // url.searchParams.set("page", bioDiariesData["page"]);
      // url.searchParams.set("type", bioDiariesData["bio_diary[bio_type]"]);

      if (bioDiariesData["bio_diary[bio_type]"] === "Urination") bioDiariesData["finalPage"] = 4
      if (bioDiariesData["bio_diary[bio_type]"] === "Stool")     bioDiariesData["finalPage"] = 7

      localStorage.setItem("bio-diaries-data", JSON.stringify(bioDiariesData));
    }
  }

  // setBioType() {
  //   const radios = document.getElementsByName('bio_diary[bio_type]');
  //
  //   if (radios.length > 0) {
  //     let url = new URL(window.location);
  //     for (const radio of radios) {
  //       radio.addEventListener('change', () => {
  //         if (radio.checked) {
  //           url.searchParams.set("type", radio.value);
  //           window.location.href = url.href;
  //         }
  //       })
  //     }
  //   }
  // }
}