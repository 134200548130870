import { Controller } from "@hotwired/stimulus"
import { setField } from "../helpers";
import { MultiStepHelper } from "../helpers/multi_step_helper";

export class MultiStepBaseController extends Controller {
  init(logType, finalPage, url, nextPageFunction = null) {
    const form = document.getElementById(`${logType}-form`);
    const previousButton = document.getElementById("previous-button");
    const jsonData = JSON.parse(localStorage.getItem(`${logType}-data`));
    const multiStepHelper = new MultiStepHelper(
        form,
        jsonData,
        previousButton,
        logType,
        finalPage,
        url,
        nextPageFunction
    );

    multiStepHelper.fillFormWithData(jsonData);
    multiStepHelper.processSubmit();
    setField();

    if (previousButton) {
      multiStepHelper.previousButtonOnClick();
    }
  }
}