import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="option-tooltips"
export default class extends Controller {
  titles = {
    'Meltdown': 'Event when the child is completely overwhelmed, and they are unable to express this ' +
        'overwhelm in another way.  A meltdown is beyond a child’s control.  Meltdowns are ' +
        'very physically tiring and emotionally draining for the individual. This is because the ' +
        'person has been in a distressing situation and has had a highly adrenalized and ' +
        'emotionalized response.  Meltdowns can include self-injury, destruction of property, ' +
        'and eloping (running away from the environment).\n\n' +
        'Meltdowns are equivalent to the fight response',
    'Shutdown': 'Event when the child may either partially or completely withdraw from the world ' +
        'around them. They may not respond to communication anymore, retreat to their ' +
        'room or lie down on the floor. They may also no longer be able to move from the ' +
        'situation they are in, no matter what it is (for example, a shopping center or a ' +
        'classroom).   Shutdowns   tend   to   be   more   discreet   than   meltdowns,   and   may ' +
        'sometimes go unnoticed.\n\n' +
        'Shutdowns are similar to the freeze response',
    'Tantrum': 'A temper tantrum is usually a child’s method for getting what they want.  Tantrum ' +
        'behavior will usually stop when the parent ignores the behavior, when the child is ' +
        'removed from a public space where the behavior is occurring, or when the child ' +
        'gets whatever it is they want',
  };

  connect() {
    this.addToolTips();
  };

  addToolTips() {
    let arr = Array.from(document.getElementsByName("episode[characterization]"));
    arr.shift();

    for (const el of arr) {
      const label = this.findLabelForControl(el);
      label.insertAdjacentHTML('beforeend', `<span class="ms-2"><i class="bi bi-question-circle-fill text-eggplant" data-bs-toggle="tooltip" data-bs-placement="bottom" title="${this.titles[label.innerText]}"></i></span>`);
    }
  }

  findLabelForControl(el) {
    const idVal = el.id;
    let labels = document.getElementsByTagName('label');
    for(let i = 0; i < labels.length; i++) {
      if (labels[i].htmlFor == idVal)
        return labels[i];
    }
  }
}
