import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  setField() {
    const date = new Date();
    const ymd = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`;
    this.getCreatedAtElements().forEach((e) => (e.value = `${ymd} ${time}`));
  }

  getCreatedAtElements() {
    if (this.hasFieldTarget) {
      return this.fieldTargets;
    } else {
      // No need to provide the element, we already know that element is in the form and we can fetch it from there.
      // The element has a name value 'object_name[created_at_wall_time]'
      return this.element.querySelectorAll("[name*='created_at_wall_time']");
    }
  }
}
