            
import { Controller } from "@hotwired/stimulus";
import { TimepickerUI } from "timepicker-ui";

export default class extends Controller {
  connect() {
    const timepickerList = document.querySelectorAll(".theme-crane-straight");
    timepickerList.forEach(timepicker => {
      const initTimepicker = new TimepickerUI(timepicker,{
        enableSwitchIcon: true,
        theme: "crane-straight",
        focusTrap: true,
        switchToMinutesAfterSelectHour: true,
        delayHandler: 50,
        editable: true,
        mobile: true,
        iconTemplate: '<i class="bi bi-phone"></i>',
        iconTemplateMobile: '<i class="bi bi-keyboard"></i>',
        focusInputAfterCloseModal: true
      });
      initTimepicker.create();
      var valueHour = $(".timepicker-ui-hour").val();
      var editHour = true;
      timepicker.addEventListener("update", (event) => {
        if (editHour){
          valueHour = $(".timepicker-ui-hour").val()
          editHour = false
        }
      });

      timepicker.addEventListener("selecthourmode", (event) => {
        if (!editHour){
          editHour = true
        }
      });
      timepicker.addEventListener("selectminutemode", (event) => {
        if(!editHour){
          $(".timepicker-ui-hour").val(valueHour)
        }
      });
    });
  }
}
            
          
