import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["currentCaregiver", "everyone"];
  connect() {
    document.addEventListener('turbo:submit-end', this.handleSubmit)
    this.currentCaregiver = JSON.parse(this.data.get("currentCaregiver"))
    this.everyone = JSON.parse(this.data.get("everyone"))
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmit)
  }

  close() {
    this.element.remove()

    this.element.removeAttribute("src")
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    const data = new FormData(e.target);

    this.createNewChat(data.get('participant'))
    if (e.detail.success) {
      this.close()
    }
  }

  async createNewChat(userId) {
    await Talk.ready;

    const otherUserData = this.everyone.find((el) => el.id === userId);
    const me = new Talk.User({
      ...this.currentCaregiver,
      welcomeMessage: 'Hello! Send at least a message to keep this chat available in your inbox'
    });

    const otherUser = new Talk.User({
      id: otherUserData.id,
      name: otherUserData.name,
      email: otherUserData.email,
      role: 'default',
      welcomeMessage: 'Hello! Send at least a message to keep this chat available in your inbox'
    });

    const conversation = talkSession.getOrCreateConversation(`${me.id}-${otherUserData.id}`);
    conversation.setParticipant(me);
    conversation.setParticipant(otherUser);

    const inbox = talkSession.createInbox({ theme: 'default' });
    await inbox.select(conversation);
    await inbox.mount(document.getElementById("talkjs-container"));
  }
}