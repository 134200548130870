import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "otherOption",
    "otherInputField"
  ];

  checkOtherOption() {
    if (!this.otherOptionTarget.checked) {
      this.otherOptionTarget.checked = true;
    }
  }
}