import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
    connect() {
        const element = this.context.scope.element;
        Array.from(element.children).forEach(child => {
            child.addEventListener("click", (e) => {
                this.toggleActive(e.target);
            }
            );
        });
    }

    toggleActive(button) {
        Array.from(button.parentElement.children).forEach(child => {
            child.classList.remove("active");
        }
        );
        button.classList.add("active");
    }
}