import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { jsPDF } from "jspdf";

export default class extends Controller {
  static values = {
    chartOptions: Object,
  };

  connect() {
    this.generateChart();
    const div = document.createElement("div");
    div.classList.add("d-flex", "justify-content-end");
    div.innerHTML = `<button class="btn btn-primary p-1 fs-7 mb-1" data-action="click->insight-report-pie#chartToPDF"> Download PDF </button>`;
    this.element.insertBefore(div, this.element.firstChild);
  }

  disconnect() {
    // Without this when I click back there are two charts.
    this.chart.destroy();
  }

  generateChart(download = false) {
    const options = {
      ...this.chartOptionsValue,
      noData: {
        text: "There is no data for the selected date range",
      },
      chart: {
        type: "pie",
        height: "500",
        width: "100%",
        fontFamily: "Sofia Pro",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: false,
        },
      },
      legend: {
        showForSingleSeries: true,
        position: "bottom",
      },

      dataLabels: {
        formatter: function (
          val,
          {
            seriesIndex,
            w: {
              config: { series, labels },
            },
          }
        ) {
          return series[seriesIndex];
        },
      },
    };

    if (download) {
      options.chart.width = "1800";
    }

    this.chart = new ApexCharts(this.element, options);

    this.chart.render();
  }

  chartToPDF() {
    this.chart.destroy();
    this.generateChart(true);
    setTimeout(() => {
      this.chart.dataURI().then(({ imgURI, blob }) => {
        const text = this.chart.el.parentElement.firstElementChild.innerHTML;
        const img = new Image();
        img.src = imgURI;
        img.onload = () => {
          var pdf = new jsPDF('l', 'px', [1800, 750]);
          pdf.setFontSize(70);
          pdf.backgroundColor = '#BFBFBF';
          pdf.addImage(imgURI, 'PNG', 10, 188, 1780, 500);
          const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          pdf.text(text, 900 - textWidth / 2, 94);
          pdf.save("download.pdf");
          const turboFrameTag = $('#insightReport');
          const src = turboFrameTag.attr('src');
          turboFrameTag.attr('src', src);
        };
      })
    }), 1000;
  }
}
