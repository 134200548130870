import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
    connect() {
        function centerPicker() {
            if ($(window).width() < 576) {
                $('.daterangepicker').css('top', '50%');
                $('.daterangepicker').css('left', '50%');
                $('.daterangepicker').css('transform', 'translate(-50%, -50%)');
            }
            const div = $('<div>').css({
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0)',
                opacity: 0,
                zIndex: 30,
                transition: 'all 0.5s ease-in-out',
            });
            div.attr('id', 'picker_overlay');
    
            if ($('#picker_overlay').length === 0) {
                $('body').append(div);
                setTimeout(function() {
                    div.css('opacity', 0.5);
                }, 100);
            }
        }

        const dateInput = $('input[name="daterange"]');
        $(function() {
            dateInput.focus(function() {
                $(this).blur();
            });
            dateInput.daterangepicker({
                opens: 'center',
                drops: 'down',
                autoApply: true,
                linkedCalendars: false,
            }, function(start, end, label) {
                const dateParam = '&date_range=' + start.format('YYYY-MM-DD') + ':' + end.format('YYYY-MM-DD')
                const reportLinks = $('.report-btn');
                reportLinks.each(function() {
                    const href = $(this).attr('href');
                    const newHref = href.includes('&') ? href.split('&')[0] + dateParam : href + dateParam;
                    $(this).attr('href', newHref);
                });
                const turboFrameTag = $('#insightReport')[0] === undefined ? $('#nutritionReport') : $('#insightReport');
                const src = turboFrameTag.attr('src') || $('#report_buttons').find('.active').attr('href');
                let newSrc;
                if($('#nutritionReport')[0] != null){
                    newSrc = src.includes('date_range') ? src.split('&date_range')[0] + dateParam : src + dateParam;
                }
                else{
                    newSrc = src.includes('&') ? src.split('&')[0] + dateParam : src + dateParam;
                }
                turboFrameTag.attr('src', newSrc);
            });
            dateInput.on('showCalendar.daterangepicker', () => {centerPicker()});
            dateInput.on('show.daterangepicker', () => {centerPicker()});

            dateInput.on('hide.daterangepicker', function() {
                $('#picker_overlay').css('opacity', 0);
                setTimeout(function() {
                    $('#picker_overlay').remove();
                }, 500);
            });

            dateInput.on('apply.daterangepicker', function(ev, picker) {
                $('#picker_overlay').css('opacity', 0);
                setTimeout(function() {
                    $('#picker_overlay').remove();
                }, 500);
                $("#loader-1").show();
            });
        });
    }
}