import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["moduleSelect"]

  change(event) {
    let module = event.target.selectedOptions[0].value
    let target
  }
}
