import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stripe"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.initStripe();
    }, 1000);
  }

  initStripe() {
    document.querySelectorAll("form").forEach(function (el) {
      el.dataset.turbo = false;
    });
    
    let stripe = Stripe(document.querySelector('meta[name="stripe-publishable-key"]').content);
    
    const clientSecret = document.getElementById("payment-form").getAttribute("data-client-secret");
    
    const options = { clientSecret };
    
    const elements = stripe.elements(options);
    
    const paymentElement = elements.create('payment');
    
    paymentElement.mount("#payment-element");
    
    const form = document.getElementById("payment-form");
    checkStatus();
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.toString().replace("payments", ""),
        },
      });
    
      if (error) {
        const messageContainer = document.querySelector("#error-message");
        messageContainer.textContent = error.message;
      } else {
      }
    });
    
    async function checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
    
      if (!clientSecret) {
        return;
      }
    
      const messageContainer = document.querySelector("#error-message");
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
    
      switch (paymentIntent.status) {
        case "succeeded":
          messageContainer.textContent = "Payment succeeded!";
          break;
        case "processing":
          messageContainer.textContent = "Your payment is processing.";
          break;
        case "requires_payment_method":
          messageContainer.textContent = "Your payment was not successful, please try again.";
          break;
        default:
          messageContainer.textContent = "Something went wrong.";
          break;
      }
    }
  }
}
