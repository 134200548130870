import {MultiStepBaseController} from "./multi_step_base_controller";

const LOG_TYPE = "episode";
const FINAL_PAGE = 6;
const URL = "/episodes";

export default class extends MultiStepBaseController {

  connect() {
    this.init(LOG_TYPE, FINAL_PAGE, URL);
    this.eventStillInProgressSwitch();
  };

  eventStillInProgressSwitch() {
    const switchButton = document.getElementById('switch');
    if (!switchButton) return;

    switchButton.addEventListener('change', () => {
      const durationInput = document.getElementById('episode_duration');
      if (switchButton.checked) {
        durationInput.value = '';
        durationInput.classList.remove('required');
        durationInput.disabled = true;
      } else {
        durationInput.classList.add('required');
        durationInput.disabled = false;
      }
    });
  }
}