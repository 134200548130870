import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash-turbo-field"
export default class extends Controller {
  connect() {
    const textField = this.context.scope.element.lastElementChild.lastElementChild;
    textField.selectionStart = textField.selectionEnd = textField.value.length;
    textField.focus();
    textField.addEventListener('keyup', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }
}