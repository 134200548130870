import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["users", "conversationId", "currentCaregiver"];

  connect() {
    this.users = this.data.get("users");
    this.conversationId = this.data.get("conversationId");
    this.currentCaregiver = this.data.get("currentCaregiver")
    this.prepareGroupChat();
  };

  async prepareGroupChat() {
    await Talk.ready;

    const me = new Talk.User(JSON.parse(this.currentCaregiver));
    const everyone = JSON.parse(this.users);

    window.talkSession = new Talk.Session({
      appId: '72bd40X6',
      me: me,
    });

    const everyoneConversation = await talkSession.getOrCreateConversation(
      this.conversationId
    );

    everyoneConversation.setParticipant(me);

    everyone.map((user) => {
      const participant = new Talk.User({
        id: user.id,
        name: user.name,
        email: user.email,
        role: 'default'
      });
      everyoneConversation.setParticipant(participant);
    })

    const inbox = talkSession.createInbox({ theme: 'default' });
    await inbox.select(everyoneConversation);
    await inbox.mount(document.getElementById("talkjs-container"));
  }
}