import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { get } from "@rails/request.js";

export default class extends Controller {

  static values = {
    series: Array,
    dataUrl: String,
    labels: Array,
  };

  connect() {
    this.noText = "Loading..";
    // I am going somewhere with this, please to not computeStyles. Experimenting with Turbo and Stimulus
    // Saw this an example for forms, but forms are being generated with ids, so I need id.
    if (this.element.id === "") {
      this.element.id = (Math.random() + 1).toString(36).substring(2);

      const params = new URLSearchParams();
      params.append("filter_from", new Date(this.getMinMaxDatesAndFormatter().min));
      params.append("turbo_element_id", this.element.id);

      get(`${this.dataUrlValue}?${params}`, {
        responseKind: "turbo-stream",
      });
    } else {
      this.noText = "There is no data logged for the last day";
    }
    this.generateChart();
  }

  disconnect() {
    // Without this when I click back there are two charts.
    this.chart.destroy();
  }

  generateChart() {
    const getYaxisLabel = () => {

      return {
        minWidth: 100,
        rotate: -45
      };
    };

    const minMaxAndFormatter = this.getMinMaxDatesAndFormatter();

    const options = {
      series: this.seriesValue,
      noData: {
        text: this.noText,
      },
      chart: {
        height: "750",
        width: "100%",
        type: "scatter",
        fontFamily: "Sofia Pro",
        zoom: {
          enabled: false,
        },
      },
      colors: [
        "#264653",
        "#2a9d8f",
        "#e76f51",
        "#f4a261",
        "#955196",
        "#118ab2",
        "#3cb44b",
        "#6c757d",
        "#d97728",
        "#d62828",
        "#1668a2",
        "#843b36"
      ],
      legend: {
        showForSingleSeries: true,
      },
      xaxis: {
        type: "datetime",
        tickAmount: 14,
        labels: {
          formatter: minMaxAndFormatter.formatter,
        },
        min: minMaxAndFormatter.min,
        max: minMaxAndFormatter.max,
      },
      yaxis: {
        tickAmount: 16,
        max: 16,
        labels: {
          ...getYaxisLabel(),
          formatter: (val) => {
            // We map 1 => Mood, and etc...
            return this.labelsValue[val];
          },
        },
      },
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  getMinMaxDatesAndFormatter() {
    // Because they are highly coupled keeping them in one place
    const date = new Date();
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;

    var min = new Date(date);
    min.setHours(min.getHours() - 26);
    min.setMinutes(0);
    // This is needed because in the label formatter we subtract it I hope there is a better way and I missed it
    min = new Date(min.getTime() - userTimezoneOffset);

    var max = new Date(date);
    max.setHours(max.getHours() + 2);
    max.setMinutes(0);
    // This is needed because in the label formatter we subtract it I hope there is a better way and I missed it
    max = new Date(max.getTime() - userTimezoneOffset);

    const f = function (val) {
      // We need to format two things
      // 1 - Remove the date and show only the hours.
      // 2 - We need to show wall time, and not converted to current time.

      const date = new Date(val);
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      const dateWallTime = new Date(date.getTime() + userTimezoneOffset);
      return dateWallTime.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    return {
      min: min.getTime(),
      max: max.getTime(),
      formatter: f,
    };
  };

}

