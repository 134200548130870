import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="blob"
export default class extends Controller {
  static values = {
    url: String,
    params: String,
  };

  connect() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = this.urlValue;
    link.click();
    setTimeout(this.close.bind(this), 10000);
  };

  close() {
    const turboFrameTag = $('#blobsView');
    turboFrameTag.attr('src', `/documents${this.paramsValue}`);
  }
}