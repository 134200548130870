import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    // Get the interval provided by the server, but at a default in order not to have error
    // if the server changes and the property is not there for some reason.
    const checkIntervalMs = parseInt(this.formTarget.dataset.checkInterval, 10) || 1000 * 5;

    // save the time initially and don't wait for the interval to expire
    // because if the date is changed during this interval we will miss the change
    this.saveTime();

    // This automaticWallDatetimeSyncOn is used only for the specs. In this way we know that we've successfully connect
    // and started the process of automatic sync with of the server.
    this.formTarget.dataset.automaticWallDatetimeSyncOn = true

    const scope = this;
    setInterval(() => {
      const shouldResubmitToServer = scope.saveTime();
      if (shouldResubmitToServer) {
        // the date will be send along with the BeMe-WallDatetime header
        // Note that we can not use .submit() because for some reason
        // it does not always attaches the BeMe-WallDatetime header; I think it is
        // because of the way turbo:* events are fired and received.
        //
        // In the same time requestSubmit() as a method on the form is not available
        // on Safari
        //
        // scope.formTarget.requestSubmit();
        scope.formTarget.querySelector("input[type=submit]").click()
      }
    }, checkIntervalMs); // check every X seconds if the date has changed. Don't know if we should be any faster than that
  }

  saveTime() {
    const newTime = new Date();
    // We are interested basically only when the date changes for the today screen so
    // we don't store the whole time. Only the toDateString()
    //
    // The user could have changed their time zone or time but if this does not impact the
    // date there is really no need to refresh the today's screen.
    //
    // We will wait for the user to interact and send a BeMe-WallDatetime header
    // to actually update the date time on the server for this user.
    const newDateString = newTime.toDateString();
    const oldDateString = this.oldDateString || newTime.toDateString();

    this.oldDateString = newDateString;
    return newDateString != oldDateString;
  }
}
