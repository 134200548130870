import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
  connect() {
    const input = this.context.scope.element
    if (input.type == 'text') {
      const idTurboFrame = this.context.scope.element.attributes[3].nodeValue
      const turboFrameTag = $('#' + idTurboFrame)
      var src = turboFrameTag.attr('src') || location.href
      input.addEventListener('keydown', function () {
        setTimeout(function () {
          const value = input.value;
          const newSrc = src + "&value=" + value;
          turboFrameTag.attr('src', newSrc);
        }, 100);
      });
      input.focus();
    }
  }
}