import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { jsPDF } from "jspdf";

export default class extends Controller {
  static values = {
    series: Array,
  };

  initialize() {
    this.colors = {
      "red": "#E8877B",
      "green": "#B4D5CF"
    };

    this.directionColor = {
      "less": this.colors["red"],
      "more": this.colors["red"],
      "within range": this.colors["green"]
    };
  }

  connect() {
    this.generateChart();

    const div = document.createElement("div");
    div.classList.add("d-flex", "justify-content-end");
    div.innerHTML = `<button class="btn btn-primary p-1 fs-7 mb-1" data-action="click->macronutrients-insight-chart#chartToPDF"> Download PDF </button>`;
    this.element.insertBefore(div, this.element.firstChild);
  }

  disconnect() {
    // Without this when I click back there are two charts.
    this.chart.destroy();
  }

  colorFunction({value, seriesIndex, dataPointIndex}) {
    return this.directionColor[
      this.seriesValue[seriesIndex].data[dataPointIndex].direction
    ];
  }

  formatterFunction(value) {
    return value == 100 ? undefined : `${value - 100}%`;
  }

  tooltipFormatterFunction(value, { series, seriesIndex, dataPointIndex, w }) {
    const dataPoint = this.seriesValue[seriesIndex].data[dataPointIndex];
    const discrepancy = Math.round((dataPoint.y-100) * 10) / 10
    const description = dataPoint.direction == "within range" ? "In Range" : `${discrepancy}% Out of Range`;
    return `${dataPoint.amount}${dataPoint.unit} (${description})`;
  }

  generateChart(download = false) {
    const options = {
      chart: {
        type: "bar",
        height: "500",
        width: "100%",
        fontFamily: "Sofia Pro",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: !download,
          speed: 500,
          animateGradually: {
              enabled: false,
          },
        },
      },
      series: this.seriesValue,
      colors: [
        this.colorFunction.bind(this),
      ],
      annotations: {
        yaxis: [{
          y: 100,
          label: {
            text: "USRDA",
            position: "left",
          }
        }]
      },
      yaxis: [
        {
          decimalsInFloat: 0,
          forceNiceScale: true,
          labels: {
            formatter: this.formatterFunction,
          },
        },
      ],
      legend: {
        markers: {
          fillColors: [
          this.colors["green"],
          this.colors["red"],
          ]
        },
        customLegendItems: [
        "In Range",
        "Out of Range"
        ],
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        theme: "dark",
        y: {
          formatter: this.tooltipFormatterFunction.bind(this),
        }
      }
    };

    if (download) {
      options.chart.width = "1800";
    }

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  chartToPDF() {
    this.chart.destroy();
    this.generateChart(true);
    setTimeout(() => {
      this.chart.dataURI().then(({ imgURI, blob }) => {
        const title = 'Nutritional Report';
        const dates = document.querySelector("input.form-control").value;
        const text = `${title} (${dates})`;
        const img = new Image();
        img.src = imgURI;
        img.onload = () => {
          var pdf = new jsPDF('l', 'px', [1800, 750]);
          pdf.setFontSize(70);
          pdf.backgroundColor = '#BFBFBF';
          pdf.addImage(imgURI, 'PNG', 10, 188, 1780, 500);
          const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          pdf.text(text, 900 - textWidth / 2, 94);
          pdf.save("download.pdf");
          const turboFrameTag = $('#snapshot_daily_micronutrient');
          const src = window.location.href;
          turboFrameTag.attr('src', src);
        };
      })
    }, 1000);
  }
}
