import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="paypal"
export default class extends Controller {
  static values = {
    planId: String,
    userUid: String,
  };

  connect() {
    setTimeout(() => {
      this.initPaypal();
    }, 1000);
  }

  initPaypal() {
    const paypal_plan_id = this.planIdValue;
    const paypal_user_uid = this.userUidValue;
    let paypalButton = paypal.Buttons({
      style: {
          shape: 'pill',
          color: 'gold',
          layout: 'horizontal',
          label: 'subscribe'
      },
      createSubscription: function(data, actions) {
        return actions.subscription.create({
          plan_id: paypal_plan_id,
          custom_id: paypal_user_uid,
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          }
        });
      },
      onApprove: function(data, actions) {
        alert('The activation of the account is in progress. Please reload the page in a few minutes.');
      }
    })
    
    paypalButton.render(this.element);
    this.paypalButton = paypalButton;
  }

  disconnect() {
    this.paypalButton.close();
    this.element.innerHTML = '';
  }
}
