import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        const micToggle = document.querySelector("#mic-toggle");
        const micIcon = document.querySelector(".bi.bi-mic");
        const textArea = micToggle.parentElement.parentElement.lastElementChild;
        const loadingUrl = textArea.style.backgroundImage;
        const audioChunks = [];
        textArea.style.backgroundImage = "none";
        
        navigator.mediaDevices.getUserMedia({ audio: { sampleRate: 48000, channelCount: 1 }})
        .then(stream => {
        let mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
        mediaRecorder.addEventListener("dataavailable", (event) => {
            audioChunks.push(event.data);
        });
        mediaRecorder.addEventListener("stop", () => {
            textArea.style.backgroundImage = loadingUrl;
            const audioBlob = new Blob(audioChunks, { type: "audio/webm" });
            audioChunks.length = 0;

            const formData = new FormData();
            formData.append("files", audioBlob);

            const crsfToken = document.querySelector("meta[name='csrf-token']").content;
            const controller = navigator.mozGetUserMedia ? "transcript_aws" : "transcript_gcp";

            fetch(`/home/${controller}`, {
            method: "POST",
            headers: {"X-CSRF-Token": crsfToken },
            body: formData
            })
            .then(response => response.json())
            .then(data => {
                textArea.style.backgroundImage = "none";
                if (data.success) {
                    textArea.placeholder = "";
                    const text = textArea.value;
                    textArea.value = text + data.transcription;
                } else {
                    textArea.value = "";
                    textArea.placeholder = "Error: " + data.message;
                }
            })
            .catch(error => console.log(error));
        });

        micToggle.addEventListener("click", ()=> {
            if (mediaRecorder.state == "inactive") {
            mediaRecorder.start()
            micIcon.style.color = "red";
            } else {
            micIcon.style.color = "white";
            mediaRecorder.stop();
            }
        })
        });
    }
}