import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
  connect() {
    const element = this.context.scope.element;    
    // Set event listener to update class when link is clicked
    element.addEventListener("change", (e) => {
      const value = e.target.value;
      Array.from(document.getElementsByClassName("form-select")).forEach(selectField => {
        selectField.value = value;
      }
      );
    }
    );
  }
}
