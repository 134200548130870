import {MultiStepBaseController} from "./multi_step_base_controller";

const LOG_TYPE = "communication";
const FINAL_PAGE = 2;
const URL = "/communications";

export default class extends MultiStepBaseController {

  connect() {
    const jsonData = JSON.parse(localStorage.getItem(`${LOG_TYPE}-data`));
    if (jsonData && jsonData["page"] === 2) {
      this.showFormTwoInputs(jsonData)
    }

    this.init(LOG_TYPE, FINAL_PAGE, URL);
  }

  showFormTwoInputs(jsonData) {
    if (jsonData["communication[mode_of_communication]"] === "Gestures or Engagement") {
      this.removeHiddenClassFromInput("gestures-or-engagement");
    } else if (jsonData["communication[mode_of_communication]"] === "Combination") {
      this.removeHiddenClassFromInput("combination");
    } else if (jsonData["communication[mode_of_communication]"] === "Communication Support System") {
      this.removeHiddenClassFromInput("communication-support-system");
    } else if (jsonData["communication[mode_of_communication]"] === "Spoken") {
      this.removeHiddenClassFromInput("spoken");
    }
  }

  removeHiddenClassFromInput(className) {
    const inputs = document.getElementsByClassName(className)
    for (const input of inputs) {
      input.classList.remove("d-none")
    }
  }
}