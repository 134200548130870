import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { get } from "@rails/request.js";

export default class extends Controller {

  static values = {
    series: Array,
    dataUrl: String,
    labels: Array,
  };

  static targets = ["sidebar", "content"]

  connect() {
    // debugger;
  }

  click() {
    this.sidebarTarget.classList.toggle("open")
    this.contentTarget.classList.toggle("bi-chevron-right")
  }
}

