import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  };

  toggle(e) {
    const csrfToken = document.querySelector("[name='csrf-token']").content;
    fetch(`/reports/toggle`, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ value: e.target.value, model: e.target.dataset.model, object_id: e.target.dataset.id, attribute: e.target.dataset.attribute })
    })
    .then(response => response.json())
  };
}