
export class MultiStepHelper {
    constructor(form,
                jsonData,
                previousButton,
                objectType,
                lastPage,
                logUrl,
                nextPageFunction = null)
    {
        this.form             = form;
        this.jsonData         = jsonData;
        this.previousButton   = previousButton;
        this.objectType       = objectType;
        this.finalPage        = lastPage;
        this.logUrl           = logUrl;
        this.nextPageFunction = nextPageFunction;
        this.localStorageKey  = `${objectType}-data`;
        this.url              = new URL(window.location);
        this.page             = Number.parseInt(this.url.searchParams.get("page")) || 1;
        this.previousPage     = Number.parseInt(this.url.searchParams.get("previousPage"));
    }

    processSubmit() {
        const eventHandler = (event) => {
            event.preventDefault();

            if(!this.validatePresence()) {
                this.displayErrorMessage("Please fill in the required fields marked in red");
                return;
            }

            const formData = new FormData(this.form);
            const logUrl = this.logUrl;
            const localStorageKey = this.localStorageKey;

            if (!this.jsonData) {
                this.jsonData = {};
            } else {
                for (const key of formData.keys()) {
                    delete this.jsonData[key]
                }
            }

            for (const pair of formData.entries()) {
                if (!pair[1]) continue;

                if (pair[0].includes("[]")) {
                    if (!this.jsonData[pair[0]]) {
                        this.jsonData[pair[0]] = [];
                    }
                    this.jsonData[pair[0]].push(pair[1])
                } else {
                    this.jsonData[pair[0]] = pair[1];
                }
            }

            if (this.page === this.finalPage) {
                this.form.removeEventListener("submit", eventHandler)
                let formData = new FormData();

                for ( const key in this.jsonData ) {
                    if (key.includes('[page]')) {
                        formData.append(key, "DONE");
                    } else {
                        formData.append(key, this.jsonData[key]);
                    }
                }

                const XHR = new XMLHttpRequest();
                XHR.open("POST", this.logUrl, true);
                XHR.onreadystatechange = () => {
                    if (XHR.readyState == 4) {
                        if (XHR.status === 200) {
                            localStorage.removeItem(localStorageKey);
                            window.location.href = '/';
                        }
                    }
                };

                XHR.addEventListener("error", () => {
                    alert("Oops! Something went wrong. Please, try again later.");
                });

                XHR.send(formData);
            } else {
                this.nextStep();
            }
        };

        if (this.form) this.form.addEventListener("submit", eventHandler);
    }

    nextStep() {
        let nextPageFunctionResult = null;
        if (this.nextPageFunction) {
            nextPageFunctionResult = this.nextPageFunction(this.jsonData)
        }

        if (nextPageFunctionResult) {
            this.url.searchParams.set("page", `${Number.parseInt(nextPageFunctionResult)}`);
            this.jsonData["previousPage"] = this.page;
        } else {
            this.url.searchParams.set("page", `${Number.parseInt(this.page) + 1}`);
        }

        this.jsonData["page"] = Number.parseInt(this.url.searchParams.get("page"));
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.jsonData));

        window.location.href = this.url.href;
    }

    previousButtonOnClick() {
        this.previousButton.addEventListener("click", (event) => {
            event.preventDefault();
            const url = new URL(window.location);

            if (this.page > 1) {
                if (this.jsonData["previousPage"]) {
                    this.page = this.jsonData["previousPage"];
                    delete this.jsonData["previousPage"];
                } else {
                    this.page -= 1;
                }

                this.jsonData["page"] = this.page;
                localStorage.setItem(this.localStorageKey, JSON.stringify(this.jsonData));
                url.searchParams.set('page', this.page);
            } else {
                return;
            }
            window.location.href = url.href;
        });
    }

    fillFormWithData(jsonData) {
        if (!jsonData) {
            return;
        }

        for (let key in jsonData) {
            if (jsonData.hasOwnProperty(key)) {
                const value = jsonData[key];
                const elements = document.getElementsByName(key);

                if (elements.length > 0) {
                    if (elements[elements.length - 1].type === 'text'
                        || elements[elements.length - 1].type === 'textarea'
                        || elements[elements.length - 1].type === 'number') {
                        elements[elements.length - 1].value = value;
                    } else if (elements[elements.length - 1].type === 'checkbox') {
                        if (Array.isArray(value)) {
                            for (let i = 0; i < elements.length; i++) {
                                elements[i].checked = value.includes(elements[i].value);
                            }
                        } else {
                            for (let i = 0; i < elements.length; i++) {
                                elements[i].checked = (elements[i].value === value);
                            }
                        }
                    } else if (elements[elements.length - 1].type === 'radio') {
                        for (let i = 0; i < elements.length; i++) {
                            if (elements[i].value === value) {
                                elements[i].checked = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    selectRadioButtonByValue(groupName, value) {
        let radioButtons = document.getElementsByName(groupName);

        for (let i = 0; i < radioButtons.length; i++) {
            if (radioButtons[i].value === value) {
                radioButtons[i].checked = true;
                break;
            }
        }
    }

    validatePresence() {
        const requiredInputs = document.querySelectorAll('input.required');
        let isValid = true;

        requiredInputs.forEach(input => {
            if (input.closest('.' + 'd-none') !== null) return;

            if (['radio', 'checkbox'].includes(input.type) && !document.querySelector(`input[name="${input.name}"]:checked`)) {
                const inputs = document.querySelectorAll(`input[name="${input.name}"]`)
                for (const input of inputs) {
                    if (input.type === 'hidden') continue;

                    input.nextElementSibling.classList.add("border", "border-red");
                }

                isValid = false;
            } else if (!input.value.trim()) {
                input.classList.add("border", "border-red");
                isValid = false;
            }
        });

        return isValid;
    }

    displayErrorMessage(errorMessage) {
        document.getElementById("error-message").classList.remove("d-none");
        document.getElementById("error-message").textContent = errorMessage;
    }
}
