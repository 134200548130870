import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  connect() {
    const url = new URL(window.location);
    const timeZone = url.searchParams.get("tz");
    const activeTabFromUrl = url.searchParams.get("type");

    this.getLocationAndTabParams(timeZone, activeTabFromUrl, url);
    this.setTab();
  };

  close() {
  }

  getLocationAndTabParams(timeZone, activeTabFromUrl, url) {
    if (timeZone && activeTabFromUrl) {
      const tab = localStorage.getItem('activeTab');
      if (activeTabFromUrl === tab) {
        return;
      } else {
        url.searchParams.set('type', tab);
        window.location.href = url.href;
      }
    }

    if (!timeZone) {
      const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      url.searchParams.append('tz', timeZoneName);
    }

    const storedTabName = localStorage.getItem('activeTab');
    if (!activeTabFromUrl && !storedTabName) {
      localStorage.setItem('activeTab', 'reminder');
      activeTabFromUrl = 'reminder';
      url.searchParams.append('type', 'reminder');
    } else if (activeTabFromUrl && !storedTabName) {
      localStorage.setItem('activeTab', activeTabFromUrl);
    } else if (!activeTabFromUrl && storedTabName) {
      url.searchParams.append('type', storedTabName);
      activeTabFromUrl = storedTabName;
    }

    window.location.href = url.href;
  }

  setTab() {
    const remindersTab = document.getElementById('reminders-tab');
    const notificationsTab = document.getElementById('notifications-tab');

    remindersTab.addEventListener('click', () => {
      localStorage.setItem('activeTab', 'reminder');
    });

    notificationsTab.addEventListener('click', () => {
      localStorage.setItem('activeTab', 'notification');
    });
  }
}