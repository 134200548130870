import {MultiStepBaseController} from './multi_step_base_controller';

const LOG_TYPE = 'daily-activities';
const FINAL_PAGE = 3;
const LOG_URL = '/daily_activities';

export default class extends MultiStepBaseController {

  connect() {
    this.page = this.getPageFromURL();
    this.setRequiredOptionalField();
    this.otherActivityFieldClear();
    this.init(LOG_TYPE, FINAL_PAGE, LOG_URL, this.setNextPageFunction);
  };

  getPageFromURL() {
    const url = new URL(window.location);
    return Number.parseInt(url.searchParams.get('page') || 1);
  }

  setNextPageFunction(dailyActivitiesData) {
    const otherActivity = dailyActivitiesData['daily_activity[other_activity]'] || '';
    const isTherapy = [dailyActivitiesData['daily_activity[activity]'].toLocaleLowerCase(),
      otherActivity.toLocaleLowerCase()].includes('therapy');

    if (isTherapy && this.page === 1) {
      return 2;
    } else {
      return 3;
    }
  }

  otherActivityFieldClear() {
    if (this.page === 1) {
      const radios = document.querySelectorAll('input[type=radio]');
      const otherActivityInput = document.getElementById('daily_activity_other_activity');

      for (const radio of radios) {
        if (radio.value === 'Other') continue;

        radio.addEventListener('change', () => {
          otherActivityInput.classList.remove('required');
          otherActivityInput.value = '';
        });
      }
    }
  }

  setRequiredOptionalField() {
    switch (this.page) {
      case 1:
        this.requireOtherValue(
            'daily_activity_activity_other',
            'daily_activity_other_activity')
        break;
      case 2:
        this.requireOtherValue(
            'daily_activity_type_of_therapy_other',
            'daily_activity_other_type_of_therapy')
        break;
    }
  }

  requireOtherValue(radioId, otherTextInputId) {
    const radio = document.getElementById(radioId);
    const otherActivityInput = document.getElementById(otherTextInputId);

    radio.addEventListener('change', () => {
      if (radio.checked) {
        otherActivityInput.classList.add('required');
      } else {
        otherActivityInput.classList.remove('required');
        document.getElementById(otherTextInputId).value = '';
      }
    });
  }
}