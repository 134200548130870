import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        const initialDate = this.data.get("selected-wall-date");
        function centerPicker() {
            if ($(window).width() < 576) {
                $('.daterangepicker').css('top', '50%');
                $('.daterangepicker').css('left', '50%');
                $('.daterangepicker').css('transform', 'translate(-50%, -50%)');
            }
            const div = $('<div>').css({
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0)',
                opacity: 0,
                zIndex: 30,
                transition: 'all 0.5s ease-in-out',

            });
            div.attr('id', 'picker_overlay');
    
            if ($('#picker_overlay').length === 0) {
                $('body').append(div);
                setTimeout(function() {
                    div.css('opacity', 0.5);
                }, 100);
            }
        }
        
        const dateInput = $('#date_picker');
        $(function() {
            dateInput.focus(function() {
                $(this).blur();
            });
            dateInput.daterangepicker({
                opens: 'center',
                drops: 'down',
                autoApply: true,
                singleDatePicker: true,
                linkedCalendars: false,
                startDate: initialDate,
            }, function(start, end, label) {
                const dateParam = start.format('YYYY-MM-DD');
                const dateForm = $('#date_selector_form');
                $('#user_wall_datetime_update_date').val(dateParam);
                dateForm.trigger('submit');
            });
            dateInput.on('showCalendar.daterangepicker', () => {centerPicker()});
            dateInput.on('show.daterangepicker', () => {centerPicker()});

            dateInput.on('hide.daterangepicker', function() {
                $('#picker_overlay').css('opacity', 0);
                setTimeout(function() {
                    $('#picker_overlay').remove();
                }, 500);
            });

            dateInput.on('apply.daterangepicker', function(ev, picker) {
                $('#picker_overlay').css('opacity', 0);
                setTimeout(function() {
                    $('#picker_overlay').remove();
                }, 500);
                $("#loader-1").show();
            });
        });
    }
}