import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modeOfCommunication"];

  connect() {
    this.modeOfCommunication = this.data.get("modeOfCommunication");
    this.addListenerToRadio();
    this.toggleInputs(this.modeOfCommunication);
  }

  addListenerToRadio() {
    const radios = document.getElementsByName("communication[mode_of_communication]");
    for (const radio of radios) {
      radio.addEventListener('change', () => {
        this.toggleInputs(radio.value, true)
      });
    }
  }

  toggleInputs(value, reset = false) {
    if (value === "Gestures or Engagement") {
      this.toggleHiddenClassFromInput("gestures-or-engagement", reset);
    } else if (value === "Combination") {
      this.toggleHiddenClassFromInput("combination", reset);
    } else if (value === "Communication Support System") {
      this.toggleHiddenClassFromInput("communication-support-system", reset);
    } else if (value === "Spoken") {
      this.toggleHiddenClassFromInput("spoken", reset);
    }
  }

  toggleHiddenClassFromInput(activeClassName, reset) {
    const classNames = ["gestures-or-engagement", "combination", "communication-support-system", "spoken"]

    for (const className of classNames) {
      const inputs = document.getElementsByClassName(className)
      for (const input of inputs) {
        input.classList.add("d-none");
        if (reset) this.clearInputs();
      }
    }

    const inputs = document.getElementsByClassName(activeClassName)
    for (const input of inputs) {
      input.classList.remove("d-none");
    }
  }

  clearInputs() {
    const checkboxInputsNames = [
      "communication[ways_of_communicating_words][]",
      "communication[communication_support_system][]"
    ];

    const textInputsNames = [
      "communication_other_ways_of_communicating_words",
      "communication_other_communication_support_system",
      "communication_description_of_words",
      "communication_words_communicated_count"
    ];

    for (const checkboxInputsName of checkboxInputsNames) {
      const inputs = document.getElementsByName(checkboxInputsName);
      for (const input of inputs) {
        input.checked = false;
      }
    }

    for (const textInputsName of textInputsNames) {
      document.getElementById(textInputsName).value = "";
    }
  }
}