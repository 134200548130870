import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { merge } from "lodash";
import { jsPDF } from "jspdf";

export default class extends Controller {
  static values = {
    chartOptions: Object,
    leftLabels: Array,
    rightLabels: Array,
  };

  connect() {
    
    this.generateChart();
    const div = document.createElement("div");
    div.classList.add("d-flex", "justify-content-end");
    div.innerHTML = `<button class="btn btn-primary p-1 fs-7 mb-1" data-action="click->insight-report#chartToPDF"> Download PDF </button>`;
    this.element.insertBefore(div, this.element.firstChild);
  }

  disconnect() {
    // Without this when I click back there are two charts.
    $("#loader-1").hide();
    this.chart.destroy();
  }

  tooltipFormatterFunction(value, { seriesIndex, dataPointIndex, w }) {
    const y = Math.round(value);
    const unit = w.config.series[seriesIndex].data[dataPointIndex].unit;
    const units = y === 1 ? unit.slice(0, -1) : unit;
    
    switch (unit) {
      case "list":
        let list = "";
        w.config.series[seriesIndex].data[dataPointIndex].list.forEach((item) => {
          const freq = item[1] === 1 ? "time" : "times";
          list += `${item[0]}: ${item[1]} ${freq}<br>`;
        }
        );
        return list;
      case "hours":
        return w.config.series[seriesIndex].data[dataPointIndex].hours;
      case "moods":
        return this.rightLabelsValue[value];
      case "acts":
        return this.leftLabelsValue[value];
      default:
        return `${y} ${units}`;
    };
  }

  generateChart(download = false) {
    const options = {
      colors:['#249df8', '#1CFC6E', '#fcb938', '#F55BE7', '#8973d4', '#FC7C2B', '#1CFCED'],
      noData: {
        text: "There is no data for the selected date range",
      },
      yaxis: [
        {
          min: 0,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        {
          min: 0,
          opposite: true,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        {
          min: 0,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            tickAmount: 6,
            max: 6,
            formatter: (val) => {
              return this.leftLabelsValue[val];
            },
          },
        },
        {
          min: 0,
          opposite: true,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            tickAmount: 6,
            max: 6,
            formatter: (val) => {
              return this.rightLabelsValue[val];
            },
          },
        },
        {
          min: 0,
          opposite: true,
          show: false,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            tickAmount: 6,
            max: 6,
            formatter: (val) => {
              return this.rightLabelsValue[val];
            },
          },
        },
        {
          min: 0,
          opposite: true,
          show: false,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            tickAmount: 6,
            max: 6,
            formatter: (val) => {
              return this.rightLabelsValue[val];
            },
          },
        },
        {
          min: 0,
          opposite: true,
          show: false,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            tickAmount: 6,
            max: 6,
            formatter: (val) => {
              return this.rightLabelsValue[val];
            },
          },
        },
        {
          min: 0,
          opposite: true,
          show: false,
          title: {
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            tickAmount: 6,
            max: 6,
            formatter: (val) => {
              return this.rightLabelsValue[val];
            },
          },
        },
      ],
      chart: {
        animations: {
          enabled: !download,
          speed: 500,
          animateGradually: {
              enabled: false,
          },
        },
        height: "500",
        width: "100%",
        fontFamily: "Sofia Pro",
        zoom: {
          enabled: false,
        },
      },
      legend: {
        showForSingleSeries: true,
      },
      plotOptions: {
        bar: {
          columnWidth: "35%",
        },
      },
      tooltip: {
        y: {
          formatter: this.tooltipFormatterFunction.bind(this),
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 1, 1, 1, 1, 1, 2],
        curve: "smooth",
      },
      markers: {
        size: 0,
      },
      xaxis: {
        type: "datetime",
      },
    };

    merge(options, this.chartOptionsValue);
    if (download) {
      options.chart.width = "1800";
    }

    this.chart = new ApexCharts(this.element, options);

    this.chart.render();
  }

  chartToPDF() {
    this.chart.destroy();
    this.generateChart(true);
    setTimeout(() => {
      this.chart.dataURI().then(({ imgURI, blob }) => {
        
        const text = this.chart.el.parentElement.firstElementChild.innerHTML;
        const img = new Image();
        img.src = imgURI;
        img.onload = () => {
          var pdf = new jsPDF('l', 'px', [1800, 750]);
          pdf.setFontSize(70);
          pdf.backgroundColor = '#BFBFBF';
          pdf.addImage(imgURI, 'PNG', 10, 188, 1780, 500);
          const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          pdf.text(text, 900 - textWidth / 2, 94);
          pdf.save("download.pdf");
          const turboFrameTag = $('#insightReport');
          const src = turboFrameTag.attr('src');
          turboFrameTag.attr('src', src);
        };
      })
    }, 1000);
  }
}