import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "password",
    "eye",
    "eyeSlash"
  ];

  toggle() {
    if (this.passwordTarget.type == "password") {
      this.passwordTarget.type = "text";
      this.eyeTarget.classList.remove("d-none");
      this.eyeSlashTarget.classList.add("d-none");
    } else {
      this.passwordTarget.type = "password";
      this.eyeSlashTarget.classList.remove("d-none");
      this.eyeTarget.classList.add("d-none");
    }
  }
}