import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.time_in_user_timezone();
    this.add_timezone_to_url();
    this.add_timezone_to_table_header();
    document.addEventListener("turbo:before-fetch-response", () => {
      this.operationsWithWaitingTime();
    });
  }

  time_in_user_timezone() {

    const numTableRows = document.getElementById('data-table').rows.length

    if (numTableRows > 1) {
      const tableCells = document.querySelectorAll('[id^="created-at-"]');

      tableCells.forEach((tableCell) => {
        const date = new Date(tableCell.dataset.time);
        tableCell.innerText = date.getFullYear() +
            '-' + ('0' + (date.getMonth() + 1)).slice(-2) +
            '-' + ('0' + (date.getDate())).slice(-2) +
            ' ' + date.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true})
      })
    }
  }

  add_timezone_to_table_header() {
    if (document.getElementById("logged-at").innerText !== 'Logged At') return;

    const table_header = document.getElementById("logged-at");
    table_header.innerText = table_header.innerText + ' (' + new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2] + ')';
  }

  add_timezone_to_url() {
    if (document.getElementById("download-button")) {
      document.getElementById("download-button").href.includes('?full_timezone_name=');
      return;
    }

    let uri = document.getElementById("download-button").href;
    const full_timezone_name = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const abbreviated_timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];

    uri += '?full_timezone_name=' + full_timezone_name + '&abbreviated_timezone=' + encodeURIComponent(abbreviated_timezone);
    document.getElementById("download-button").href = uri;
  }

  findUnformattedDate() {
    const table = document.getElementById("data-table");
    const rows = table.rows;
    for (let i = 1; i < rows.length; i++) {
      const cols = rows[i].cells;
      for (let c = 0; c < cols.length; c++) {
        if (cols[c].innerText === '-') {
          return true;
        }
      }
    }
  }

  runFormattingOperations() {
    this.time_in_user_timezone();
    this.add_timezone_to_url();
    this.add_timezone_to_table_header();
  }

  operationsWithWaitingTime() {
    setTimeout(() => {
      this.runFormattingOperations();
      if (this.findUnformattedDate()) {
        setTimeout(() => {
          this.runFormattingOperations();
          if (this.findUnformattedDate()) {
            setTimeout(() => {
              this.runFormattingOperations();
            }, 1000);
          }
        }, 1000);
      }
    }, 500);
  }
}