import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "destination"];

  connect() {
    this.destinationTargets.forEach((t) => {
      t.classList.add("d-none");
    });
  }

  setValuesFromSourceToDestinations() {
    if (this.hasSourceTarget) {
      // FIXME this is because the data-attribute is given to the label and the input and I did not want to go in and
      // change how metronic form builders are rendered
      const source = this.sourceTargets.filter((t) => t.tagName == "INPUT")[0];
      if (source && this.hasDestinationTarget) {
        this.destinationTargets.forEach((t) => {
          t.value = source.value;
        });
      }
    }
  }
}
