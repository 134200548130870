import { Controller } from "@hotwired/stimulus";

// FIXME. Remove this and use the wall date from the server
export default class extends Controller {
  static targets = ["field"];

  setField() {
    const date = new Date();
    const ymd = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    this.fieldTargets.forEach(e => e.value = ymd);
  }
}
