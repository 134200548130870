import {MultiStepBaseController} from "./multi_step_base_controller";

const LOG_TYPE = "wake-up";
const FINAL_PAGE = 3;
const URL = "/sleeps";

export default class extends MultiStepBaseController {

  connect() {
    this.init(LOG_TYPE, FINAL_PAGE, URL);
  };
}