import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["percentageBar"]

  connect() {
    const bar = this.percentageBarTarget;

    var progress = 0;
    const intervalId = setInterval(function(){
      bar.setAttribute("style", `width: ${progress}%`)
      progress+=1;
      if(progress >=100) {
        progress=0;
        // clearInterval(intervalId);
      }
    },1000);
  }
}