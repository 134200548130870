import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { jsPDF } from "jspdf";

export default class extends Controller {
  static values = {
    chartOptions: Array,
  };

  connect() {
    this.generateChart();

    const div = document.createElement("div");
    div.classList.add("d-flex", "justify-content-end");
    div.innerHTML = `<button class="btn btn-primary p-1 fs-7 mb-1" data-action="click->insight-report-bar#chartToPDF"> Download PDF </button>`;
    this.element.insertBefore(div, this.element.firstChild);
  }

  disconnect() {
    // Without this when I click back there are two charts.
    this.chart.destroy();
  }

  tooltipFormatterFunction(value, { seriesIndex, dataPointIndex, w }) {
    const title = this.chartOptionsValue[dataPointIndex].x;
    return `${title}: ${value}`;
  }

  generateChart(download = false) {
    var options = {
      colors:['#249df8', '#1CFC6E', '#fcb938', '#F55BE7', '#8973d4', '#FC7C2B', '#1CFCED'],
      noData: {
        text: "There is no data for the selected date range",
      },
      series: [
        {
          name: "",
          data: this.chartOptionsValue.map((data) => data.y),
        },
      ],
      chart: {
        animations: {
          enabled: !download,
          speed: 500,
          animateGradually: {
              enabled: false,
          },
        },  
        height: 500,
        width: "100%",
        fontFamily: "Sofia Pro",
        type: "bar",
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
      },
      yaxis: [
        {
          title: {
            text: "Number of BMs",
            style: {
              fontSize: "25px",
              fontWeight: 300,
            },
          },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
      ],
      xaxis: {
        categories: this.chartOptionsValue.map((data) => data.x),
        labels: {
          formatter: function (value) {
            return "";
          },
        },
      },
      tooltip: {
        y: {
          formatter: this.tooltipFormatterFunction.bind(this),
        }
      },
    };

    if (download) {
      options.chart.width = "1800";
    }

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  chartToPDF() {
    this.chart.destroy();
    this.generateChart(true);
    setTimeout(() => {
      this.chart.dataURI().then(({ imgURI, blob }) => {
        const title = this.chart.el.parentElement.firstElementChild.innerHTML;
        const dates = document.querySelector("input.form-control").value;
        const text = `${title} (${dates})`;
        const img = new Image();
        img.src = imgURI;
        img.onload = () => {
          var pdf = new jsPDF('l', 'px', [1800, 750]);
          pdf.setFontSize(70);
          pdf.backgroundColor = '#BFBFBF';
          pdf.addImage(imgURI, 'PNG', 10, 188, 1780, 500);
          const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          pdf.text(text, 900 - textWidth / 2, 94);
          pdf.save("download.pdf");
          const turboFrameTag = $('#insightReport');
          const src = turboFrameTag.attr('src');
          turboFrameTag.attr('src', src);
        };
      })
    }, 1000);
  }
}
