import { Tooltip } from "bootstrap";
import { Controller } from "@hotwired/stimulus";

// Experimenting here.
// https://turbo.hotwired.dev/handbook/building#observing-navigation-events
// This could be done with `document.addEventListener("turbo:load", function() {`, but
// When possible, avoid using the turbo:load event to add other event listeners directly to elements on the page body
// So I wanted to try this.

export default class extends Controller {
  connect() {
    const tooltipTriggerList = [].slice.call(
      this.element.querySelectorAll('[data-bs-toggle="tooltip"]')
    );

    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }
}
