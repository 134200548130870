import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    const divBox = this.context.scope.element;
    const closeButton = divBox.lastElementChild;
    setTimeout(() => {
      closeButton.click();
    }, 10000);
  }
}
