import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bioType"];

  connect() {
    this.bioType = this.data.get("bioType");
    this.addListenerToRadio();
    this.toggleTypes(this.bioType);
  }

  addListenerToRadio() {
    const radios = document.getElementsByName("bio_diary[bio_type]");
    for (const radio of radios) {
      radio.addEventListener('change', () => {
        this.toggleTypes(radio.value, true)
      });
    }
  }

  toggleTypes(value, reset = false) {
    if (value === "Urination") {
      this.toggleHiddenClassFromInput("urination", reset);
    } else if (value === "Stool") {
      this.toggleHiddenClassFromInput("stool", reset);
    }
  }

  toggleHiddenClassFromInput(activeDivId, reset) {
    const divIds = ["stool", "urination"]

    for (const divId of divIds) {
      const div = document.getElementById(divId)
      div.classList.add("d-none");
      if (reset) this.clearInputs();
    }

    const activeDiv = document.getElementById(activeDivId)
    activeDiv.classList.remove("d-none");
  }

  clearInputs() {
    const checkboxInputsNames = [
      "bio_diary[stool_type]",
      "bio_diary[stool_smells]",
      "bio_diary[additional_contents_of_stool_sample][]",
      "bio_diary[child_appears_bloated]",
      "bio_diary[volume_of_urine]",
      "bio_diary[tried_postponing_urination]"
    ];

    const textInputsNames = [
      "bio_diary_stool_color",
      "bio_diary_stool_observations"
    ];

    for (const checkboxInputsName of checkboxInputsNames) {
      const inputs = document.getElementsByName(checkboxInputsName);
      for (const input of inputs) {
        input.checked = false;
      }
    }

    for (const textInputsName of textInputsNames) {
      document.getElementById(textInputsName).value = "";
    }
  }
}