import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static values = {
    series: Array,
    dates: Array,
  };

  connect() {
    this.generateChart();
  }

  disconnect() {
    // Without this when I click back there are two charts.
    this.chart.destroy();
  }

  generateChart() {
    const options = {
      series: this.seriesValue,
      xaxis: {
        categories: this.datesValue,
      },
      noData: {
        text: "There is no sleep data for the last 7 days",
      },
      yaxis: [
        {
          seriesName: "Time",
          min: 0,
          forceNiceScale: true,
          showForNullSeries: false,
          labels: {
            formatter: (seconds) => {
              const hours = seconds / 60 / 60;
              const sign = hours < 0 ? "-" : "";
              const h = Math.floor(Math.abs(hours));
              const s = Math.floor((Math.abs(hours) * 60) % 60);
              return sign + (h < 10 ? "0" : "") + h + ":" + (s < 10 ? "0" : "") + s;
            },
          },
        },
      ],
      chart: {
        height: "500",
        width: "100%",
        fontFamily: "Sofia Pro",
        zoom: {
          enabled: false,
        },
      },
      legend: {
        showForSingleSeries: true,
      },
      plotOptions: {
        bar: {
          columnWidth: "35%",
        },
      },
      fill: {
        opacity: [0.3, 1, 1],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [0, 2, 2],
        curve: "smooth",
      }
    };

    this.chart = new ApexCharts(this.element, options);

    this.chart.render();
  }
}
