import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field"];

  connect() {
    let message = "Hello";
    const date = new Date();
    const hours = date.getHours();
    if (hours < 12){
      message = "Good morning"
    }else if(hours >= 12 && hours <= 16){
      message = "Good afternoon"
    }else{
      message = "Good evening"
    }

    this.element.innerText = message;
  }
}