import { Controller } from "@hotwired/stimulus"
// TawkToController
export default class extends Controller {

  connect() {
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/60f5cffb649e0a0a5cccfa15/1fb038sgj';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    // NOTE: NotByTheBook Setting data-turbolinks-track,'reload' here.
    // It is different than the examples, but se need this because we are using turbolinks
    // For turbolinks to refresh the headers on the page and to execute the scripts again
    // we need to set data-turbolinks-track to reload. Otherwize it wont.
    s1.setAttribute('data-turbolinks-track', 'reload');
    s0.parentNode.insertBefore(s1,s0);
    })();
    }
  // …
}