import { Controller } from "@hotwired/stimulus";
import ApexCharts from "apexcharts";
import { jsPDF } from "jspdf";

export default class extends Controller {
  static values = {
    series: Array,
    xlabels: Array,
    isSingleNutrition: Boolean,
  };

  connect() {
    this.generateChart();
    const div = document.createElement("div");
    div.classList.add("d-flex", "justify-content-end");
    div.innerHTML = `<button class="btn btn-primary p-1 fs-7 mb-1" data-action="click->nutrition#chartToPDF"> Download PDF </button>`;
    this.element.insertBefore(div, this.element.firstChild);
  }

  disconnect() {
    // Without this when I click back there are two charts.
    this.chart.destroy();
  }

  colorFunction({ value, seriesIndex, dataPointIndex, w }) {
    let color = ''
    const data = w.globals.initialSeries[seriesIndex];
    if (data.data[dataPointIndex] > 100) {
      color = '#249df8';
    } else {
      color = '#E8877B';
    }
    return color;
  }

  tooltipSingle({ series, seriesIndex, dataPointIndex, w }) {
    const data = w.globals.initialSeries[seriesIndex];
    const dataRecommended = w.globals.initialSeries[1];
    const date = new Date(w.globals.lastXAxis.categories[dataPointIndex].split('T')[0] + "T00:00:00-07:00").toLocaleDateString('en-us', { month: "short", day: "numeric" });
    let color_text = '';
    let data_y = '';
    if (data.data[dataPointIndex] > 100) {
      color_text = 'text-success';
      data_y = '+' + (data.data[dataPointIndex] - 100).toFixed(2);
    } else {
      color_text = 'text-danger';
      data_y = (data.data[dataPointIndex] - 100).toFixed(2);
    }
    return '<div class="d-flex align-items-center flex-column d-block d-md-none" style="font-size: x-small"> <span>' + date +
      '</span> <ul class="list-group px-2 py-2">' +
      '<li class="list-group-item d-flex align-items-center">' +
      '<span class="badge badge-secondary badge-pill mx-1"> </span> <div class="d-flex flex-column"> <span>' +
      dataRecommended.valueX[seriesIndex] + 'g</span><span> Recommended (USFDA) </span> </div></li>' +
      '<li class="list-group-item d-flex align-items-center ' + color_text + '">' +
      '<span class="badge badge-primary badge-pill mx-1"> </span> <div class="d-flex flex-column"> <span>' +
      data.valueX[dataPointIndex] + 'g</span><span> (' + data_y + '%) <span class="text-dark ms-1">Actual</span> </span> </div></li>' +
      '</ul> </div><div class="px-2 d-none d-md-block"> <span>' + date +
      '</span> <ul class="list-group px-2 py-2">' +
      '<li class="list-group-item d-flex align-items-center">' +
      '<span class="badge badge-secondary badge-pill mx-1"> </span>' +
      dataRecommended.valueX[seriesIndex] + 'g Recommended (USFDA) </li>' +
      '<li class="list-group-item d-flex align-items-center ' + color_text + '">' +
      '<span class="badge badge-primary badge-pill mx-1"> </span>' +
      data.valueX[dataPointIndex] + 'g (' + data_y + '%) <span class="text-dark ms-1">Actual</span></li>' +
      '</ul> </div>';
  }

  tooltipMultiple({ series, seriesIndex, dataPointIndex, w }) {
    const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
    let color_text = '';
    let data_y = '';
    if (data.y > 100) {
      color_text = 'text-success';
      data_y = '+' + (data.y - 100).toFixed(2);
    } else {
      color_text = 'text-danger';
      data_y = (data.y - 100).toFixed(2);
    }
    return '<div class="d-flex align-items-center flex-column d-block d-md-none" style="font-size: x-small"> <span>' + w.globals.labels[dataPointIndex] +
      '</span> <ul class="list-group px-2 py-2">' +
      '<li class="list-group-item d-flex align-items-center">' +
      '<span class="badge badge-secondary badge-pill mx-1"> </span> <div class="d-flex flex-column"> <span>' +
      data.goals[0].valueG + 'g</span><span> Recommended (USFDA) </span> </div></li>' +
      '<li class="list-group-item d-flex align-items-center ' + color_text + '">' +
      '<span class="badge badge-primary badge-pill mx-1"> </span> <div class="d-flex flex-column"> <span>' +
      data.valueG + 'g</span><span> (' + data_y + '%) <span class="text-dark ms-1">Actual</span> </span> </div></li>' +
      '</ul> </div> <div class="px-2 d-none d-md-block"> <span>' + w.globals.labels[dataPointIndex] +
      '</span> <ul class="list-group px-2 py-2">' +
      '<li class="list-group-item d-flex align-items-center">' +
      '<span class="badge badge-secondary badge-pill mx-1"> </span>' +
      data.goals[0].valueG + 'g Recommended (USFDA) </li>' +
      '<li class="list-group-item d-flex align-items-center ' + color_text + '">' +
      '<span class="badge badge-primary badge-pill mx-1"> </span>' +
      data.valueG + 'g (' + data_y + '%) <span class="text-dark ms-1">Actual</span></li>' +
      '</ul> </div>';
  }

  stylePattern(seriesValue) {
    let array = new Array();
    for (var i = 0; i < seriesValue.length; i++) {
      if (seriesValue[i].y > 100) {
        array.push('circles');
      } else {
        array.push('slantedLines');
      }
    }
    return array;
  }

  generateChart(download = false) {
    let seriesArray;
    let xAxisType;
    let horizontal;
    let distributed;
    let showLegend;
    let xAxisLabels;
    let yAxisLabels;
    let xTitle;
    let tooltip;
    let annotations;
    let fill;
    let colors;
    if (this.isSingleNutritionValue) {
      seriesArray = this.seriesValue
      xAxisType = "datetime";
      horizontal = false;
      distributed = false;
      showLegend = true;
      xAxisLabels = {};
      yAxisLabels = {
        formatter: function (val) {
          return `${val - 100}%`;
        }
      }
      xTitle = '';
      tooltip = {
        custom: this.tooltipSingle.bind(this),
        followCursor: true,
      }
      annotations = {
        yaxis: [{
          y: '100',
          borderWidth: 3,
          label: {
            text: "Daily Recommended",
            position: "left",
          }
        }]
      }
      fill = {
        opacity: [0.6, 1, 1],
      }
      colors = [this.colorFunction.bind(this)]
    } else {
      seriesArray = [{
        data: this.seriesValue,
      }]
      xAxisType = "numeric";
      horizontal = true;
      distributed = true;
      showLegend = false;
      xTitle = 'Percentage';
      xAxisLabels = {
        formatter: function (val) {
          return val - 100;
        }
      };
      yAxisLabels = {};
      tooltip = {
        custom: this.tooltipMultiple.bind(this),
        followCursor: true,
      }
      annotations = {
        xaxis: [
          {
            x: '100',
            borderColor: '#00C645',
            borderWidth: 3,
            label: {
              text: "Daily Recommended",
              position: "left",
              orientation: 'horizontal',
            },
          }
        ],
      }
      fill = {
        type: 'pattern',
        pattern: {
          style: this.stylePattern(this.seriesValue),
          width: 6,
          height: 6,
          strokeWidth: 5,
        },
        opacity: [0.8],
      }
      colors = ['#249df8', '#1CFC6E', '#fcb938', '#F55BE7', '#8973d4', '#FC7C2B', '#1CFCED']
    }
    const options = {
      colors: colors,
      series: seriesArray,
      xaxis: {
        tickAmount: 3,
        title: {
          text: xTitle,
        },
        categories: this.xlabelsValue,
        labels: xAxisLabels,
        type: xAxisType,
      },
      noData: {
        text: "There is no nutrition data for the last 7 days",
      },
      yaxis: {
        decimalsInFloat: 0,
        forceNiceScale: true,
        labels: yAxisLabels,
      },
      tooltip: tooltip,
      chart: {
        height: "500",
        stacked: true,
        fontFamily: "Sofia Pro",
        type: 'bar',
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: !download,
          speed: 500,
          animateGradually: {
            enabled: false,
          },
        },
      },
      annotations: annotations,
      legend: {
        show: showLegend,
        showForSingleSeries: true,
        markers: {
          fillColors: [
            '#249df8',
            '#E8877B',
          ]
        },
      },
      plotOptions: {
        bar: {
          distributed: distributed,
          horizontal: horizontal,
          columnWidth: "35%",
        },
      },
      fill: fill,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: this.isSingleNutritionValue ? [0, 2, 2] : 0,
        curve: "smooth",
      },
    };

    if (download) {
      options.chart.width = "1800";
    }

    this.chart = new ApexCharts(this.element, options);

    this.chart.render();
    $("#loader-1").hide();
  }

  chartToPDF() {
    this.chart.destroy();
    this.generateChart(true);
    setTimeout(() => {
      this.chart.dataURI().then(({ imgURI, blob }) => {
        const text = this.chart.el.parentElement.firstElementChild.innerHTML;
        const img = new Image();
        img.src = imgURI;
        img.onload = () => {
          var pdf = new jsPDF('l', 'px', [1800, 750]);
          pdf.setFontSize(70);
          pdf.backgroundColor = '#BFBFBF';
          pdf.addImage(imgURI, 'PNG', 10, 188, 1780, 500);
          const textWidth = pdf.getStringUnitWidth(text) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
          pdf.text(text, 900 - textWidth / 2, 94);
          pdf.save("download.pdf");
          const turboFrameTag = $('#nutritionReport');
          const src = $('#report_buttons').find('.active').attr('href');
          turboFrameTag.attr('src', src);
        };
      })
    }), 1000;
  }
}