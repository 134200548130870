// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "./controllers"

// NOTE. Import only what you really need from bootstrap and write down at least
// one of the things you need it for.
//
// Collapse - needed for navbar
import { Collapse } from 'bootstrap';
import { Dropdown } from 'bootstrap';
ActiveStorage.start()

// This ofcourse will work only with js enabled where we add the header
document.addEventListener('turbo:before-fetch-request', async (event) => {
  event.detail.fetchOptions.headers["BeMe-WallDatetime"] = new Date().toString();
})


// document.addEventListener("turbo:load", function(event) {
//   window.dataLayer = window.dataLayer || []
//   function gtag(){dataLayer.push(arguments)}
//   gtag('js', new Date())
//   gtag('config', 'UA-213615903-1', {'page_location': event.detail.url})
// }, false)