import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="notifications"
export default class extends Controller {
  connect() {
    this.formatTimeField();
    this.init_modal();
    document.getElementById("notification_time_zone").value = Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  close() {
  }

  formatTimeField() {
    const timeInput = document.getElementById('notification_notify_at');

    if (timeInput.value) {
      timeInput.value = new Date(timeInput.value)
          .toLocaleString('en-US', {hour: '2-digit', minute: '2-digit', hour12: true});
    } else {
      timeInput.value = "08:00 AM"
    }
  }

  init_modal() {
    const form = document.getElementById("notification-form");

    form.addEventListener("submit", async (event) => {
      event.preventDefault();

      const checkInputs = document.querySelectorAll('[name^="notification[contact_methods][]"]')
      let checkBoxesStatus = []

      for (const checkInput of checkInputs) {
        checkBoxesStatus.push(checkInput.checked);
      }

      if (!checkBoxesStatus.includes(true)) {
        alert('No contact method was selected. Please select one or more');
        return;
      }
      form.submit();
    });
  }

  // categoryOnChange() {
  //   const categorySelect = document.getElementById('notification_job_class');
  //
  //   categorySelect.addEventListener('change', (event) => {
  //     switch (categorySelect.value) {
  //       case 'EpisodeHappened':
  //       case 'CommunicationHappened':
  //       case 'ExcrementHappened':
  //       case 'PhysicalSymptomsHappened':
  //       case 'BehaviorsHappened':
  //       case 'SupplementTaken':
  //         this.toggleFormInputs(true);
  //         break;
  //       default:
  //         this.toggleFormInputs(false);
  //     }
  //   });
  // }

  // toggleFormInputs(disabled) {
  //   const notifyMeCheckbox = document.getElementById('notify_me_checkbox');
  //   const notifyHiddenInput = document.getElementById('notification_notify_me');
  //   const timeInput = document.getElementById('notification_notify_at');
  //   const targetConditionInput = document.getElementById('notification_target_condition');
  //   const targetPercentageInput = document.getElementById('notification_target_percentage');
  //
  //   notifyMeCheckbox.disabled = disabled;
  //   timeInput.disabled = disabled;
  //   targetConditionInput.disabled = disabled;
  //   targetPercentageInput.disabled = disabled;
  //
  //   notifyMeCheckbox.checked = "checked";
  //   notifyHiddenInput.value = disabled;
  //   timeInput.value = disabled ? '' : '08:00 AM';
  //   targetConditionInput.value = disabled ? '' : 'Under';
  // }
}
