import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
  connect() {
    const input = this.context.scope.element
    const nutrientParams = this.context.scope.element.attributes
    const turboFrameTag = $('#modal')
    var src = turboFrameTag.attr('src') || location.href
    const idCheck = input.value.toString().toLowerCase().replaceAll(',', '').replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '_').replaceAll('+', '')
    const check = $("#reporting_nutrition_nutrition_select_" + idCheck);
    const checkInput = src.split('&check=')[1];
    input.addEventListener('click', function () {
      setTimeout(function () {
        if (input.checked) {
          var nutrientList = JSON.parse(nutrientParams[3].nodeValue.toString());
          nutrientList.push(input.value.toString().replaceAll('+', '_'))
        }
        else {
          var nutrientList = JSON.parse(nutrientParams[4].nodeValue.toString());
          nutrientList.splice(nutrientList.indexOf(input.value.toString().replaceAll('+', '_')), 1);
        }
        nutrientList = nutrientList.filter((c, index) => {
          return nutrientList.indexOf(c) === index;
        });
        var nutrient_check = ''
        for (var i = 0; i < nutrientList.length; i++) {
          nutrient_check += "&nutrient_check%5B%5D=" + encodeURI(nutrientList[i].toString());
        }
        nutrient_check += "&check=" + idCheck
        src = src.split('&value=')[0]
        const newSrc = src.split('&nutrient_check%5B%5D=')[0] + nutrient_check
        turboFrameTag.attr('src', newSrc);
      }, 100);
    });
    setTimeout(function () {
      if (checkInput == idCheck){
        check.removeClass("btn-check");
        check.focus();
        check.addClass("btn-check");
      }
    }, 50);
  }
}